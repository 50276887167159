/**
COLORS
 */
@red-xlight :lighten (#f28f8c, 25%);
/*  Button colors  */
/* Dialog text color */
/* States */
/* VALUES */
/* Colors from MUI theme: */
@keyframes opacity-fade {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
@keyframes opacity-flash {
  0% {
    opacity: 0;
  }
  10% {
    opacity: 1;
  }
  90% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
