@import '~@kapeta/ui-web-components/styles/index';

body {
    overflow: auto;

    #root {
        height: 100%;
        .application {
            height: 100%;
        }
    }

    * {
        box-sizing: border-box;
    }
}

// poor mans devtools - highlight elements with kap-id
// [data-kap-id] {
//     box-shadow: 0 0 5px #f00 !important;
// }
